import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllFraksi = createAsyncThunk(
    'Fraksi/fetchAllFraksi',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.FRAKSI, params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addFraksi = createAsyncThunk(
    'Fraksi/addFraksi',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.FRAKSI, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateFraksi = createAsyncThunk(
    'Fraksi/updateFraksi',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', `${URLS.FRAKSI}/${payload.id}`, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneFraksi = createAsyncThunk(
    'Fraksi/fetchOneFraksi',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.FRAKSI}/${id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteFraksi = createAsyncThunk(
    'Fraksi/deleteFraksi',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.FRAKSI}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const FraksiSlice = createSlice({
    name: 'Fraksi',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllFraksi.pending, startLoadingQuery)
            .addCase(fetchAllFraksi.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllFraksi.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneFraksi.pending, startLoadingQuery)
            .addCase(fetchOneFraksi.rejected, stopLoadingQuery)
            .addCase(fetchOneFraksi.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateFraksi.pending, startLoadingQuery)
            .addCase(updateFraksi.rejected, stopLoadingQuery)
            .addCase(updateFraksi.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(deleteFraksi.pending, startLoadingMutation)
            .addCase(deleteFraksi.fulfilled, stopLoadingMutation)
            .addCase(deleteFraksi.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = FraksiSlice.actions;

export default FraksiSlice.reducer;