import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { strings } from "res";
import Loading from "components/shared-components/Loading";

// Pages
import DASHBOARD from "./dashboard";
import CATEGORIES from "./categories";
import CONTENTS from "./contents";
import DEWAN from "./dewan";
import FRAKSI from "./fraksi";
import KOMISI from "./komisi";
import SIDANG from "./sidang";
import MESSAGES from "./messages";
import USERS from "./users";
import SUBCATEGORY from "./subcategories";

// Detail
import DETAIL_CATEGORY from "./detail-category";
import DETAIL_CONTENT from "./detail-content";
import DETAIL_DEWAN from "./detail-dewan";
import DETAIL_SIDANG from "./detail-sidang";
import DETAIL_MESSAGE from "./detail-message";
import DETAIL_KOMISI from "./detail-komisi";
import DETAIL_FRAKSI from "./detail-fraksi";
import DETAIL_USER from "./detail-user";
import DETAIL_TAG from "./detail-tag";
import DETAIL_SUBCATEGORY from "./detail-subcategory";

import SETTINGS from "./settings";
import { TAGS } from "./tags";
import { STATIC } from "./static";
import DETAIL_STATIC from "./static-detail";

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          path={`${strings.navigation.path.dashboard}`}
          component={DASHBOARD}
        />
        <PrivateRoute
          path={`${strings.navigation.path.categories}`}
          component={CATEGORIES}
        />
        <PrivateRoute
          path={`${strings.navigation.path.subcategories}`}
          component={SUBCATEGORY}
        />
        <PrivateRoute
          path={`${strings.navigation.path.sidang}`}
          component={SIDANG}
        />
        <PrivateRoute
          path={`${strings.navigation.path.contents}`}
          component={CONTENTS}
        />
        <PrivateRoute
          path={`${strings.navigation.path.komisi}`}
          component={KOMISI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.fraksi}`}
          component={FRAKSI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.dewan}`}
          component={DEWAN}
        />
        <PrivateRoute
          path={`${strings.navigation.path.messages}`}
          component={MESSAGES}
        />
        <PrivateRoute
          path={`${strings.navigation.path.users}`}
          component={USERS}
        />
        <PrivateRoute
          path={`${strings.navigation.path.tags}`}
          component={TAGS}
        />

        {/* DETAIL */}
        <PrivateRoute
          path={`${strings.navigation.path.detail_content}`}
          component={DETAIL_CONTENT}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_fraksi}`}
          component={DETAIL_FRAKSI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_komisi}`}
          component={DETAIL_KOMISI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_dewan}`}
          component={DETAIL_DEWAN}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_sidang}`}
          component={DETAIL_SIDANG}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_category}`}
          component={DETAIL_CATEGORY}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_message}`}
          component={DETAIL_MESSAGE}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_user}`}
          component={DETAIL_USER}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_tag}`}
          component={DETAIL_TAG}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_subcategory}`}
          component={DETAIL_SUBCATEGORY}
        />

        <PrivateRoute
          path={`${strings.navigation.path.settings}`}
          component={SETTINGS}
        />

        {/* STATIC */}
        <PrivateRoute
          path={`${strings.navigation.path.static_list}`}
          component={STATIC}
        />

        <PrivateRoute
          path={`${strings.navigation.path.static_detail}`}
          component={DETAIL_STATIC}
        />

        <Redirect
          from={`${match.url}`}
          to={`${strings.navigation.path.dashboard}`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
