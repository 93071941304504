import { Col, DatePicker, Row, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "antd";
import {
  addFraksi,
  fetchOneFraksi,
  updateFraksi,
} from "redux/features/fraksi";
import { MyEditor } from "../../../components/Editor";
import ImageUploader from "components/shared-components/ImageUploader";
import moment from "moment";

const selectStyle = {
  width: "100%",
  backgroundColor: "white",
};

const rules = [
  {
    required: true,
    message: "Wajib Diisi!",
  },
];

export const ADD_FRAKSI = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();

  const [idBody, setIdBody] = useState("");
  const [enBody, setEnBody] = useState("");
  const [subcategories, setSubcategories] = useState();
  const [tags, setTags] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [imgUrlDisplay, setImgUrlDisplay] = useState("");

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData(values);
    } else {
      createData(values);
    }
  };

  const createData = async (values) => {
    console.log("create data :", values);
    try {
      await dispatch(
        addFraksi({
          ...values
        })
      ).unwrap();
      history.push("/app/fraksi");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const updateData = async (values) => {
    try {
      await dispatch(
        updateFraksi({
          // ...values,
          photo:imgUrl ? imgUrl : undefined,
          user_id: 1,
          id: location?.state?.id,
        })
      ).unwrap();
      history.push("/app/fraksi");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneFraksi(id)).unwrap();
      form.setFieldsValue({
        ...data.data
      });
      setImgUrlDisplay(data.data.photo)
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageUpload = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setImgUrl(info.file.response.data.secure_url);
    }
  };

  useEffect(() => {
    console.log(location?.state?.id)
    if (location?.state?.id) {
      getDataById(location?.state?.id);
    }
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tambah/Update Data Fraksi</h2>
          <p>Isi data dewan sesuai dengan data yang ada.</p>
        </Col>
      </Row>
      <Row  gutter={24}>
        <Col xs={18} sm={18} md={18} lg={18} gap={3}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Nama Fraksi"
                name="name"

              >
                <Input />
              </Form.Item>

              <Form.Item>
                <MyEditor
                  data={idBody}
                  setState={setIdBody}
                  defaultValue="Deskripsi"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} gap={3}>
          <Card>
            <img src={imgUrlDisplay} style={{width:"100%"}}></img>
            <br></br>
            <h3>Ubah Foto Profil</h3>
            <Form.Item
                label="Foto Profil"
                name="photo"
              >
                <ImageUploader imageUrl={imgUrl} onChange={handleImageUpload} />
              </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ADD_FRAKSI;
