import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllMeeting = createAsyncThunk(
    'Meeting/fetchAllMeeting',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.MEETING, params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addMeeting = createAsyncThunk(
    'Meeting/addMeeting',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.MEETING, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateMeeting = createAsyncThunk(
    'Meeting/updateMeeting',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', `${URLS.MEETING}/${payload.id}`, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneMeeting = createAsyncThunk(
    'Meeting/fetchOneMeeting',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.MEETING}/${id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteMeeting = createAsyncThunk(
    'Meeting/deleteMeeting',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.MEETING}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const MeetingSlice = createSlice({
    name: 'Meeting',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllMeeting.pending, startLoadingQuery)
            .addCase(fetchAllMeeting.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllMeeting.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneMeeting.pending, startLoadingQuery)
            .addCase(fetchOneMeeting.rejected, stopLoadingQuery)
            .addCase(fetchOneMeeting.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateMeeting.pending, startLoadingQuery)
            .addCase(updateMeeting.rejected, stopLoadingQuery)
            .addCase(updateMeeting.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(deleteMeeting.pending, startLoadingMutation)
            .addCase(deleteMeeting.fulfilled, stopLoadingMutation)
            .addCase(deleteMeeting.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = MeetingSlice.actions;

export default MeetingSlice.reducer;