import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllDewan = createAsyncThunk(
    'Dewan/fetchAllDewan',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.DEWAN, params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addDewan = createAsyncThunk(
    'Dewan/addDewan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.DEWAN, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateDewan = createAsyncThunk(
    'Dewan/updateDewan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', `${URLS.DEWAN}/${payload.id}`, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneDewan = createAsyncThunk(
    'Dewan/fetchOneDewan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.DEWAN}/${id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteDewan = createAsyncThunk(
    'Dewan/deleteDewan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.DEWAN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const DewanSlice = createSlice({
    name: 'Dewan',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllDewan.pending, startLoadingQuery)
            .addCase(fetchAllDewan.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllDewan.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneDewan.pending, startLoadingQuery)
            .addCase(fetchOneDewan.rejected, stopLoadingQuery)
            .addCase(fetchOneDewan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateDewan.pending, startLoadingQuery)
            .addCase(updateDewan.rejected, stopLoadingQuery)
            .addCase(updateDewan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(deleteDewan.pending, startLoadingMutation)
            .addCase(deleteDewan.fulfilled, stopLoadingMutation)
            .addCase(deleteDewan.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = DewanSlice.actions;

export default DewanSlice.reducer;