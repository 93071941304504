import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllKomisi, deleteKomisi } from "redux/features/komisi";
import moment from "moment";
import { fetchAllCategory } from "redux/features/category";
import { capitalize, filter } from "lodash";
import { fetchAllSubcategory } from "redux/features/subcategory";

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Ok"
        cancelText="Cancel"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const KOMISI = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [totalData, setTotalData] = useState(0);
  const [modal, contextHolder] = Modal.useModal();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    total_data: 0,
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: ()=> {
        return <div style={{textAlign:"center"}}>Nomor Komisi</div>
      },
      dataIndex: "nomor",
      key: "nomor",
      render:(_,record)=>{
        return (<div style={{textAlign:"center"}}>{record.nomor}</div>)
      }
    },
    {
      title: "Bidang",
      dataIndex: "description",
      key: "description",
    },
   
    {
      title: () => <div className="text-center">Detail</div>,
      key: "detail",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_komisi}`,
                state: record,
              });
            }}
          >
            Detail
          </Button>
        </div>
      ),
    },
    {
      title: () => <div className="text-center">Action</div>,
      key: "status",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="danger"
            style={{ textAlign: "center", color: "white" }}
            onClick={() => {
              confirm(record.id);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchAllKomisi(params)).unwrap();
      setMetaData(response.meta.page);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteKomisi(id));
        getData(filters);
      },
      onCancel: () => { },
    });
  };

  const handleCategorySelect = (val) => {
    setFilters({ ...filters, category_id: val });
  };

  const handleSubcategorySelect = (val) => {
    setFilters({ ...filters, subcategory_id: val });
  };

  const handleSearchByTitle = (event) => {
    setFilters({ ...filters, title: event.target.value ? event.target.value : undefined });
  }

  const handleTableChange = (pagination, filter, sorter) => {
    let sorterKey = "";

    setFilters({ ...filters, page: pagination.current });

    if (sorter.field === "date_published" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

    if (sorter.field === "created_at" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

  };

  useEffect(() => {
    getData(filters)
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Komisi</h2>
          <p>Tampilkan semua komisi beserta data dewan.</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[16, 2]}>
              <Col xs={24} sm={24} md={24} lg={24} span={6}>
                <Input
                  placeholder="Cari Berdasarkan Nama"
                  onSelect={handleSearchByTitle}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto", }}
              scroll={{ x: '100%' }}  // Menambahkan scrolling horizontal
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              loading={isLoading}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: metaData.page,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_komisi}`,
              });
            }}
            block
          >
            Tambah Komisi
          </Button>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default withRouter(KOMISI);
