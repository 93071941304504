import moment from "moment-timezone";


export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, "days").tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem("token"),
  api: {
    host: "https://api.banyuasinkab.info/api/v1",
    storage: "https://storage.banyuasinkab.info/upload",
    upload_api:"https://api.banyuasinkab.info/api/file-upload/upload",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: "url(/img/background.png)",
    logo: "/img/logo-colored.png",
    logo2: "/img/logo-colored.png",
    favicon: "",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00",
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Dashboard",
  },
  navigation: {
    login: "/auth/login",
    register: "/auth/register",
    main: "/app",
    path: {
      login: "login",
      register: "register",
      forgot_password: "forgot-password",
      activate: "activate",
      resend: "resend",
      please: "please",
      logout: "logout",
      dashboard: "/app/dashboard",
      dewan: "/app/dewan",
      messages: "/app/messages",
      contents: "/app/contents",
      categorization: "/app/categorization",
      detail_content: "/app/detail-content",
      categories: "/app/categories",
      subcategories: "/app/subcategories",
      detail_category: "/app/detail-category",
      detail_categorization: "/app/detail-categorization",
      detail_user: "/app/detail-user",
      detail_dewan: "/app/detail-dewan",
      detail_sidang: "/app/detail-sidang",
      detail_komisi: "/app/detail-komisi",
      detail_fraksi: "/app/detail-fraksi",
      detail_dapil: "/app/detail-dapil",
      detail_message: "/app/detail-message",
      users: "/app/users",
      komisi: "/app/komisi",
      fraksi: "/app/fraksi",
      pns: "/app/pns",
      sidang: "/app/sidang",
      profile: "/app/profile",
      settings: "/app/settings",
      roles: "/app/roles",
      tags: "/app/tags",
      detail_tag: "/app/detail-tag",
      detail_subcategory: "/app/detail-subcategory",
      static_list: "/app/statics",
      static_detail: "/app/detail-static",
    },
  },
};
