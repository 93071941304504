import { Col, DatePicker, Row, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "antd";
import {
  addMeeting,
  fetchOneMeeting,
  updateMeeting,
} from "redux/features/sidang";
import { MyEditor } from "../../../components/Editor";
import ImageUploader from "components/shared-components/ImageUploader";
import moment from "moment";

const selectStyle = {
  width: "100%",
  backgroundColor: "white",
};

const rules = [
  {
    required: true,
    message: "Wajib Diisi!",
  },
];

export const ADD_SIDANG = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();

  const [idBody, setIdBody] = useState("");
  const [enBody, setEnBody] = useState("");
  const [subcategories, setSubcategories] = useState();
  const [tags, setTags] = useState();
  const [imgUrl, setImgUrl] = useState("");

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData(values);
    } else {
      createData(values);
    }
  };

  const createData = async (values) => {
    console.log("create data :", values);
    try {
      // YYYY-MM-DDTHH:mm
      await dispatch(
        addMeeting({
          ...values,
          date: moment(values.date).format('YYYY-MM-DDTHH:mm'),
        })
      ).unwrap();
      history.push("/app/sidang");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  // {
  //   "name": "Team Meeting",
  //   "location": "Conference Room B, Floor 2",
  //   "date": "2025-01-10T10:30"
  // }

  const updateData = async (values) => {
    try {
      await dispatch(
        updateMeeting({
          ...values,
          id: location?.state?.id,
          date: moment(values.date).format('YYYY-MM-DDTHH:mm'),
        })
      ).unwrap();
      history.push("/app/sidang");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneMeeting(id)).unwrap();
      form.setFieldsValue({
        ...data.data,
        category: data.data.category_id,
        // content_tags: data.data.content_tags.map((item) => item.tag_id),
        date: moment(data.data.date),
      });
      setEnBody(data?.data.body_en);
      setIdBody(data?.data.body);
      setImgUrl(data.data.thumbnail);
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageUpload = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setImgUrl(info.file.response.secure_url);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id);
    }
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tambah/Update Sidang</h2>
          <p>Tambah sidan dan pengaturan sidang paripurna, kegiatan lainnya</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Judul Sidang"
                name="name"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Lokasi Sidang"
                name="location"

              >
                <Input />
              </Form.Item>

              {/* <Form.Item
                label="Thumbnail"
                name="thumbnail"
                rules={[
                  {
                    required: true,
                    message: "Please upload your Thumbnail",
                  },
                ]}
              >
                <ImageUploader imageUrl={imgUrl} onChange={handleImageUpload} />
              </Form.Item> */}

              <Form.Item
                label="File URL"
                name="file"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Jadwal Sidang"
                name="date"
                rules={rules}
              >
                <DatePicker showTime format="YYYY-MM-DDTHH:mm" />
              </Form.Item>

              <Form.Item>
                <MyEditor
                  data={idBody}
                  setState={setIdBody}
                  defaultValue="Indonesian Content"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Buat Sidang
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ADD_SIDANG;
